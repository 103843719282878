import {createStore as reduxCreateStore, applyMiddleware, combineReducers, compose} from 'redux'

// Middlewares
import {createLogger} from 'redux-logger'
import serviceMiddleware from 'inst-redux-service-middleware'
import alertTimerMiddleware from '../middleware/alertTimerMiddleware'
import navigationMiddlewareCreator from '../middleware/navigationMiddleware'
import timerSettingsMiddleware from '../middleware/timerSettingsMiddleware'
import extraAttemptsPerQuizMiddleware from '../middleware/giveExtraAttemptsMiddleware'
import extraTimePerQuizMiddleware from '../middleware/giveExtraTimeMiddleware'
import saveAccommodationsMiddleware from '../middleware/saveAccommodationsMiddleware'

// Reducers
import alerts from '../reducers/alerts'
import assignmentSessions from '../reducers/assignmentSessions'
import attemptHistory from '../reducers/attemptHistory'
import context from '../reducers/context'
import contentExports from '../reducers/contentExports'
import features from '../reducers/features'
import moderation from '../reducers/moderation'
import oauthToken from '../reducers/oauthToken'
import launchToken from '../reducers/launchToken'
import participantSessions from '../reducers/participantSessions'
import preview from '../reducers/preview'
import sidebar from '../reducers/sidebar'
import timerSettings from '../reducers/timerSettings'
import giveExtraAttemptsSettings from '../reducers/giveExtraAttempts'
import giveExtraTimeSettings from '../reducers/giveExtraTime'
import users from '../reducers/users'
import ux from '../reducers/ux'
import versions from '../reducers/versions'
import coolingPeriodTimer from '../reducers/coolingPeriodTimer'
import header from '../reducers/header'

// Services
import LtiApiService from '../services/LtiApiService'

export default function configureStore(router) {
  const loggerMiddleware = createLogger({collapsed: true})
  const navigationMiddleware = navigationMiddlewareCreator(router)

  const reducers = combineReducers({
    alerts,
    assignmentSessions,
    attemptHistory,
    context,
    coolingPeriodTimer,
    contentExports,
    features,
    moderation,
    oauthToken,
    launchToken,
    participantSessions,
    preview,
    sidebar,
    timerSettings,
    giveExtraAttemptsSettings,
    giveExtraTimeSettings,
    users,
    ux,
    versions,
    header,
  })

  const productionMiddleware = [
    alertTimerMiddleware,
    navigationMiddleware,
    timerSettingsMiddleware,
    extraAttemptsPerQuizMiddleware,
    extraTimePerQuizMiddleware,
    saveAccommodationsMiddleware,
    serviceMiddleware({ltiApi: new LtiApiService()}),
  ]

  const middleware =
    process.env.NODE_ENV === 'production'
      ? productionMiddleware
      : [...productionMiddleware, loggerMiddleware]

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = reduxCreateStore(
    reducers,
    undefined,
    composeEnhancers(applyMiddleware(...middleware)),
  )
  store.router = router // eslint-disable-line immutable/no-mutation
  return store
}
