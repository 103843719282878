import {CALL_SERVICE} from 'inst-redux-service-middleware'

import {
  SCOPE_QUIZ_GRADE,
  SCOPE_QUIZ_LOG,
  SCOPE_QUIZ_SHOW,
  SCOPE_QUIZ_RESULTS,
  SCOPE_QUIZ_TAKE,
} from '../constants'

import {
  createAssignmentSessionHandler,
  handleAssignmentSessionUpdate,
} from '../actions/assignmentSessions'
import {navigateToParticipantSession, navigateToResults} from '../actions/apiActionHandlers'
import {storeFeatures} from '../actions/features'
import {updateOauthToken} from '../actions/oauthToken'
import {createParticipantSessionHandler} from '../actions/participantSessions'
import {loadPreviewData} from '../actions/preview'
import {
  addParticipantsInProgress,
  createParticipants,
  updateEnrollmentHandler,
  updateParticipantHandler,
  setNumAutogradingFailed,
} from '../actions/moderation'
import {storeUser, storeUsers} from '../actions/users'
import {storeCourse, storeCourses} from '../actions/courses'
import {storeVersion} from '../actions/versions'
import {storeContentExport, storeContentExports} from '../actions/contentExports'

export const getAssignmentToken = ({activeAssignmentId, scope, outcomesScope}) => {
  let params = `scope=${scope}`
  if (outcomesScope) {
    params += `&outcomesScope=${outcomesScope}`
  }
  return {
    payload: {
      args: [
        {
          path: `/assignments/${activeAssignmentId}?${params}`,
          resolveAction: createAssignmentSessionHandler(scope, outcomesScope),
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const updateAssignment = ({activeAssignmentId, outcomesScope, scope, settings}) => {
  let params = `scope=${scope}`
  if (outcomesScope) {
    params += `&outcomesScope=${outcomesScope}`
  }
  return {
    payload: {
      args: [
        {
          body: settings,
          path: `/assignments/${activeAssignmentId}?${params}`,
          resolveAction: handleAssignmentSessionUpdate(scope, outcomesScope),
        },
      ],
      method: 'PATCH',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getToken = ({scope, outcomesScope, launchToken}) => {
  let params = new URLSearchParams()

  if (outcomesScope) {
    params.append('outcomesScope', outcomesScope)
  }

  if (launchToken) {
    params.append('launch_token', launchToken)
  }

  params = params.toString().length > 0 ? `?${params.toString()}` : ''

  return {
    payload: {
      args: [
        {
          path: `/sdk_tokens/${scope}${params}`,
          resolveAction: createAssignmentSessionHandler(scope),
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getParticipantSessionAndRedirect = (
  {quizSessionId},
  resolveAction = navigateToResults,
) => {
  return {
    payload: {
      args: [
        {
          path: `/quiz_sessions/${quizSessionId}`,
          resolveAction,
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getLatestParticipantSessionAndRedirect = (
  {participantId},
  resolveAction = navigateToParticipantSession,
) => {
  return {
    payload: {
      args: [
        {
          path: `/participants/${participantId}/participant_sessions/latest`,
          resolveAction,
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

const getParticipants = (
  {activeAssignmentId, page, perPage, progressFilter, search, no_pagination},
  resolveAction = createParticipants,
) => {
  if (!page && !no_pagination) {
    throw new Error('Missing page number')
  }
  const query = {
    progress: progressFilter || undefined,
    search: search || undefined,
    ...(no_pagination && {no_pagination}),
    ...(page && {page}),
    ...(perPage && {perPage}),
  }
  return {
    payload: {
      args: [
        {
          path: `/assignments/${activeAssignmentId}/participants`,
          query: query,
          resolveAction,
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getAllInProgressParticipantsForModeration = (
  {activeAssignmentId},
  resolveAction = addParticipantsInProgress,
) => {
  const req = {activeAssignmentId, progressFilter: 'in_progress', no_pagination: true}
  return getParticipants(req, resolveAction)
}

export const getParticipantsForModeration = (
  {activeAssignmentId, page, perPage, progressFilter, search},
  resolveAction = createParticipants,
) => {
  const req = {activeAssignmentId, page, perPage, progressFilter, search}
  return getParticipants(req, resolveAction)
}

export const getNumAutogradingFailed = ({activeAssignmentId}) => {
  return getParticipants(
    {
      activeAssignmentId,
      page: 1,
      perPage: 1, // Only care about the `Total` header. Don't actually need any participants, but 0 is invalid.
      progressFilter: 'autograding_failed',
    },
    setNumAutogradingFailed,
  )
}

export const participantSessionCreate = ({participantId}, resolveAction) => {
  return {
    payload: {
      args: [
        {
          path: `/participants/${participantId}/participant_sessions`,
          resolveAction,
        },
      ],
      method: 'POST',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const participantSessionShow = ({participantSessionId}) => {
  return {
    payload: {
      args: [
        {
          path: `/participant_sessions/${participantSessionId}`,
          resolveAction: createParticipantSessionHandler(SCOPE_QUIZ_SHOW, participantSessionId),
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const participantSessionUpdate = ({participantSessionId, status}, resolveAction) => {
  return {
    payload: {
      args: [
        {
          path: `/participant_sessions/${participantSessionId}`,
          body: {
            participant_session: {
              status: status,
            },
          },
          resolveAction,
        },
      ],
      method: 'PATCH',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const take = ({participantSessionId}, onError) => {
  return {
    payload: {
      args: [
        {
          path: `/participant_sessions/${participantSessionId}/take`,
          resolveAction: createParticipantSessionHandler(SCOPE_QUIZ_TAKE, participantSessionId),
          onError: onError,
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const results = ({participantSessionId}) => {
  return {
    payload: {
      args: [
        {
          path: `/participant_sessions/${participantSessionId}/results`,
          resolveAction: createParticipantSessionHandler(SCOPE_QUIZ_RESULTS, participantSessionId),
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const grade = ({participantSessionId}) => {
  return {
    payload: {
      args: [
        {
          path: `/participant_sessions/${participantSessionId}/grade`,
          resolveAction: createParticipantSessionHandler(SCOPE_QUIZ_GRADE, participantSessionId),
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const generateAnalysis = ({assignmentId, filter}, onError) => {
  return {
    payload: {
      args: [
        {
          path: `/assignments/${assignmentId}/analyses`,
          body: {filter},
          onError,
        },
      ],
      method: 'POST',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getSessionLogToken = ({quizSessionId}) => {
  return {
    payload: {
      args: [
        {
          path: `/quiz_sessions/${quizSessionId}/log`,
          resolveAction: createParticipantSessionHandler(SCOPE_QUIZ_LOG, quizSessionId),
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const createPreviewSession = ({assignmentId, printOnLoad}) => {
  return {
    payload: {
      args: [
        {
          path: `/assignments/${assignmentId}/preview`,
          query: {
            printOnLoad: printOnLoad ? 1 : 0,
          },
          resolveAction: loadPreviewData,
        },
      ],
      method: 'POST',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const createObservedSession = ({activeAssignmentId, scope}) => {
  return {
    payload: {
      args: [
        {
          path: `/assignments/${activeAssignmentId}/observe?scope=${scope}`,
          resolveAction: createAssignmentSessionHandler(scope),
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getTokenInfo = () => {
  return {
    payload: {
      args: [
        {
          path: '/oauth/token/info',
          resolveAction: updateOauthToken,
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const refreshToken = tokenInfo => {
  const refreshTokenBody = {
    refresh_token: tokenInfo.refreshToken,
    grant_type: tokenInfo.tokenType,
  }

  return {
    payload: {
      args: [
        {
          body: refreshTokenBody,
          path: '/oauth/token',
          resolveAction: updateOauthToken,
          token: tokenInfo,
        },
      ],
      method: 'POST',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getFeatures = ({exhaustive} = {exhaustive: false}) => {
  return {
    payload: {
      args: [
        {
          path: '/features',
          resolveAction: storeFeatures,
          exhaustive,
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getUser = ({userId}) => {
  return {
    payload: {
      args: [
        {
          path: `/users/${userId}`,
          resolveAction: storeUser,
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getUsers = ({roles, searchString, uuid}) => {
  return {
    payload: {
      args: [
        {
          path: '/users',
          resolveAction: storeUsers,
          query: {
            roles,
            searchString,
            uuid,
          },
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const addUser = ({userData}) => {
  return {
    payload: {
      args: [
        {
          path: '/users',
          resolveAction: storeUser,
          body: userData,
        },
      ],
      method: 'POST',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const updateEnrollment = ({contextId, enrollment, participantId, userId}) => {
  if (!contextId) {
    throw new Error('Missing contextId')
  }
  if (!participantId) {
    throw new Error('Missing participantId')
  }
  if (!userId) {
    throw new Error('Missing userId')
  }

  return {
    payload: {
      args: [
        {
          path: `/enrollment?context_id=${contextId}&user_id=${userId}`,
          resolveAction: updateEnrollmentHandler({contextId, participantId, userId}),
          body: enrollment,
        },
      ],
      method: 'PUT',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getVersion = () => {
  return {
    payload: {
      args: [
        {
          path: `/versions`,
          resolveAction: storeVersion,
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const updateParticipant = ({participantId, participant, postActionHandler}) => {
  return {
    payload: {
      args: [
        {
          path: `/participants/${participantId}`,
          resolveAction: updateParticipantHandler({participantId, postActionHandler}),
          body: participant,
        },
      ],
      method: 'PUT',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getCourseSubaccounts = ({canvasCourseId, searchString, uuids}) => {
  return {
    payload: {
      args: [
        {
          path: `/contexts/${canvasCourseId}/sub_accounts/course_accounts`,
          query: {
            searchTerm: searchString,
            uuids,
          },
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getAdminSubaccounts = ({searchString, uuids}) => {
  return {
    payload: {
      args: [
        {
          path: '/sub_accounts/adminable_accounts',
          query: {
            searchTerm: searchString,
            uuids,
          },
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getCourses = ({searchString, uuid, contextType}) => {
  return {
    payload: {
      args: [
        {
          path: '/contexts',
          resolveAction: storeCourses,
          query: {
            searchString,
            uuid,
            contextType,
          },
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getCourseAssignments = course_uuid => {
  return {
    payload: {
      args: [
        {
          path: '/assignments',
          // resolveAction: resolver, we don't care about getting this info into the store currently
          query: {
            context_uuid: course_uuid,
          },
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const addCourse = ({courseData}) => {
  return {
    payload: {
      args: [
        {
          path: '/contexts',
          resolveAction: storeCourse,
          body: courseData,
        },
      ],
      method: 'POST',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const rcsJwtRefreshFn = token => {
  return {
    payload: {
      args: [
        {
          path: '/rce/refresh_jwt',
          body: {token},
        },
      ],
      method: 'POST',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const contentExport = ({contextUuid, exportSettings}) => {
  return {
    payload: {
      args: [
        {
          path: '/content_exports',
          body: {
            content_export: {
              context_uuid: contextUuid,
              export_settings: exportSettings,
            },
          },
        },
      ],
      method: 'POST',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getContentExportsForQuiz = quizId => {
  return {
    payload: {
      args: [
        {
          path: '/content_exports',
          resolveAction: storeContentExports,
          query: {quizId},
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}

export const getContentExport = exportId => {
  return {
    payload: {
      args: [
        {
          path: `/content_exports/${exportId}`,
          resolveAction: storeContentExport,
        },
      ],
      method: 'GET',
      service: 'ltiApi',
    },
    type: CALL_SERVICE,
  }
}
